import { MODULES } from '@/utils/constants';
import { isArray } from 'lodash';
import { computed, ComputedRef, ref, Ref } from 'vue';
import { RouteLocationNormalized } from 'vue-router';
import { hasModuleForCurrentOrg } from '@/composables/useUser';

type NavigationHistory = {
    from: RouteLocationNormalized;
    to: RouteLocationNormalized;
}

const history: Ref<NavigationHistory[]> = ref([]);
const state: ComputedRef<NavigationHistory | null> = computed(() => {
    return history.value[history.value.length - 1] || null;
});

const onPage = (from: RouteLocationNormalized, to: RouteLocationNormalized): void => {
    history.value.push({ from, to });

    // Keep the history to a maximum of 30 items
    if (history.value.length > 30) {
        history.value.shift();
    }
};

const getParentAppendRoute = (ignorePrefix = ''): string => {
    const parts = state.value && state.value.to ? state.value.to.fullPath.split('/') : [];
    return parts.length && parts[1] && parts[1] !== ignorePrefix ? `${parts[1]}.` : '';
};

const getGivingReportsFromRoute = (): string | null => {
    for (let i = history.value.length - 1; i >= 0; i--) {
        if (history.value[i]?.from.name?.toString().startsWith('giving.reports')) {
            return history.value[i].from.fullPath;
        }
    }

    return null;
};

const contributionFromRoute = ref('');

const storeContributionFromRoute = (): void => {
    // If the user is coming from a giving.reports path, store the route
    const fromGivingReports = getGivingReportsFromRoute();
    if (fromGivingReports) {
        contributionFromRoute.value = fromGivingReports;
        return;
    }

    if (state.value?.from.fullPath.includes('edit') || (state.value?.from.fullPath === state.value?.to.fullPath)) {
        return;
    }

    contributionFromRoute.value = state.value ? state.value.from.fullPath : '';
};

const getDefaultRoute = (moduleName?: string): string => {
    if (moduleName && hasModuleForCurrentOrg(moduleName)) {
        return `${moduleName}.dashboard`;
    }

    return 'dashboard';
};

const getCurrentModule = (): string => {
    return getParentAppendRoute('my-profile').slice(0, -1);
};

// This checks if the user has the module for the current org based on the route path.
const userHasModule = (): boolean => {
    return hasModuleForCurrentOrg(getCurrentModule());
};

// If a user is in the Giving or Launch module, the app should check if the user has that module.
const shouldCheckIfUserHasModule = (): boolean => {
    return [MODULES.GIVING, MODULES.LAUNCH].some((module) => {
        return !!getParentAppendRoute().match(module);
    });
};

const routeNameIncludes = (names: string | string[]) => {
    if (isArray(names)) {
        return names.some((name) => {
            return state.value?.to.name?.toString().includes(name);
        });
    }

    return state.value?.to.name?.toString().includes(names as string);
};

const routePathIncludes = (names: string | string[]) => {
    if (isArray(names)) {
        return names.some((name) => {
            return state.value?.to.path?.toString().includes(name);
        });
    }

    return state.value?.to.path?.toString().includes(names as string);
};

export {
    state,
    onPage,
    getParentAppendRoute,
    storeContributionFromRoute,
    contributionFromRoute,
    getDefaultRoute,
    userHasModule,
    shouldCheckIfUserHasModule,
    getCurrentModule,
    routeNameIncludes,
    routePathIncludes,
    getGivingReportsFromRoute
};
