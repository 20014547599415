<template>
    <svg
        viewBox="0 0 594.1 126.3"
        class="h-full"
        fill="#ffffff"
    >
        <path
            d="M158.2,46.6c-8.8,0-14.2,5.7-14.2,16.9c0,10.3,5.1,16.8,14.6,16.8c8.6,0,11.4-5.2,13.1-11.5l8,2.1
			c-2.1,10.7-8.5,17.1-21,17.1c-14.7,0-23.6-8.9-23.6-24.4c0-15.5,9.3-24.7,23.3-24.7c13.1,0,19.2,6.2,21.1,17.2l-8.1,1.7
			C169.8,50.9,166.6,46.6,158.2,46.6z"
        />
        <path
            d="M193.7,86.9h-8.7V22.6h8.7v29.6h0.7c2.7-8.2,8.6-13.3,17.1-13.3c10.2,0,14.9,6.8,14.9,17v30.9h-8.7V58
			c0-6.4-2.8-11.2-10-11.2c-8.5,0-14,5.3-14,13.3V86.9z"
        />
        <path
            d="M266.4,74.6h-0.8c-2.6,8.3-8.8,13.4-16.9,13.4c-10.5,0-15.2-6.9-15.2-17.1V40.1h8.7v28.7
			c0,6.5,2.7,11.2,10.2,11.2c8.3,0,13.8-5.2,13.8-13.2V40.1h8.7v46.8h-8.5V74.6z"
        />
        <path
            d="M282.8,86.9V40.1h8.4v13.2l0.7,0.1c2.4-10.2,7.2-13.8,14.4-13.8h1.8v9.6h-2.4c-10.1,0-14.2,4-14.2,14.5v23.1
			H282.8z"
        />
        <path
            d="M331.9,46.6c-8.8,0-14.2,5.7-14.2,16.9c0,10.3,5.1,16.8,14.6,16.8c8.6,0,11.4-5.2,13.1-11.5l8,2.1
			c-2.1,10.7-8.5,17.1-21,17.1c-14.7,0-23.6-8.9-23.6-24.4c0-15.5,9.3-24.7,23.3-24.7c13.1,0,19.2,6.2,21.1,17.2l-8.1,1.7
			C343.5,50.9,340.3,46.6,331.9,46.6z"
        />
        <path
            d="M367.3,86.9h-8.7V22.6h8.7v29.6h0.7c2.7-8.2,8.6-13.3,17.1-13.3c10.2,0,14.9,6.8,14.9,17v30.9h-8.7V58
			c0-6.4-2.8-11.2-10-11.2c-8.5,0-14,5.3-14,13.3V86.9z"
        />
        <path
            d="M431.4,86.9h-8.7V22.6h8.7v29.6h0.7c2.7-8.2,8.6-13.3,17.1-13.3c10.2,0,14.9,6.8,14.9,17v30.9h-8.7V58
			c0-6.4-2.8-11.2-10-11.2c-8.5,0-14,5.3-14,13.3V86.9z"
        />
        <path
            d="M500.9,75.7H500c-2.3,7.7-8.3,12.1-16.6,12.1c-8.8,0-14.5-4-14.5-13c0-8.1,5.2-14,16.6-14h15V57
			c0-7-2.7-10.8-10.3-10.8c-6.3,0-9.8,3.1-11.3,9.3l-8.2-2c1.9-9,8.6-14.5,19.8-14.5c13,0,18.7,6.3,18.7,17.9v29.9h-8.3V75.7z
			 M500.5,69.4v-2.6h-13.7c-6.1,0-9.6,2.4-9.6,7c0,3.7,2.3,7,9.2,7C495,80.9,500.5,76,500.5,69.4z"
        />
        <path d="M525.9,86.9h-8.7V22.6h8.7V86.9z"/>
        <path
            d="M531.4,63.3c0-15.3,9.2-24.4,23.4-24.4c14.1,0,23.4,9.1,23.4,24.4c0,15.2-8.6,24.7-23.4,24.7
			C540,88.1,531.4,78.5,531.4,63.3z M540.1,63.2c0,10.5,4.7,17.2,14.6,17.2c9.9,0,14.8-6.7,14.8-17.2s-5.1-16.8-14.7-16.8
			C545.2,46.4,540.1,52.7,540.1,63.2z"
        />
        <path
            d="M70.8,32.2c1.4,0.8,2.8,1.7,4.1,2.7c1.8,1.5,3.5,3.2,4.9,5.1c1.7,2.4,3.1,5.1,3.9,8c0.5,1.6,0.8,3.2,0.9,4.9h0
		h4.8c-0.1-1.7-0.4-3.3-0.8-4.9c-0.8-3.4-2.2-6.6-4-9.4c-1.4-2.1-3-4.1-4.9-5.8c-1.5-1.4-3.1-2.7-4.9-3.7c-1.3-0.8-2.7-1.5-4.1-2.1
		V32.2z"
        />
        <path
            d="M38.9,48c2.7-7.3,9.3-12.6,17.3-13.6v-5.1c-10.8,1-19.7,8.6-22.6,18.7h-5h-5C26.8,32.5,40,20.7,56.2,19.6v-4.9
		C37.3,15.9,21.9,29.8,18.6,48c-0.3,1.6-0.5,3.2-0.6,4.9h4.9h4.9h4.9h5.1C37.9,51.2,38.3,49.6,38.9,48"
        />
        <path
            d="M74.9,52.9h4.6c-0.2-1.7-0.6-3.3-1.2-4.9c-0.8-2.2-2-4.2-3.4-6c-2.3-2.9-5.4-5.1-9-6.4v-5.4v-5v-5
		c3.2,0.7,6.2,1.7,9,3.2c1.7,0.9,3.3,1.9,4.9,3c1.8,1.3,3.4,2.7,4.9,4.3c1.9,2,3.5,4.2,4.9,6.5c1.9,3.3,3.3,6.9,4.1,10.7
		c0.3,1.6,0.6,3.2,0.7,4.9h0.1h4.8c-0.1-1.7-0.3-3.3-0.6-4.9c-0.8-4.3-2.2-8.3-4.2-12c-1.4-2.5-3-4.9-4.9-7.1
		c-1.5-1.7-3.1-3.3-4.9-4.8c-1.5-1.3-3.2-2.4-4.9-3.5c-1.6-1-3.2-1.8-4.9-2.5c-2.8-1.2-5.8-2.2-9-2.7c-1.6-0.3-3.2-0.5-4.9-0.6v4.9
		v4.9v4.9v5.1v4.8c1.7,0.3,3.4,0.8,4.9,1.5c3.1,1.6,5.7,4.1,7.2,7.2c0.8,1.5,1.3,3.1,1.5,4.9H74.9z"
        />
        <path
            d="M46.4,78.4c-1.4-0.8-2.8-1.7-4.1-2.7c-1.8-1.5-3.5-3.2-4.9-5.1c-1.7-2.4-3.1-5.1-3.9-8
		c-0.5-1.6-0.8-3.2-0.9-4.9h0h-4.8c0.1,1.7,0.4,3.3,0.8,4.9c0.8,3.4,2.2,6.6,4,9.4c1.4,2.1,3,4.1,4.9,5.8c1.5,1.4,3.1,2.7,4.9,3.7
		c1.3,0.8,2.7,1.5,4.1,2.1V78.4z"
        />
        <path
            d="M78.3,62.6C75.6,69.9,69,75.3,61,76.2v5.1c10.8-1,19.7-8.6,22.6-18.7h5h5C90.4,78.1,77.2,89.9,61,91v4.9
		c18.9-1.1,34.3-15.1,37.6-33.3c0.3-1.6,0.5-3.2,0.6-4.9h-4.9h-4.9h-4.9h-5.1C79.3,59.5,78.9,61.1,78.3,62.6"
        />
        <path
            d="M42.3,57.8h-4.6c0.2,1.7,0.6,3.3,1.2,4.9c0.8,2.2,2,4.2,3.4,6c2.3,2.9,5.4,5.1,9,6.4v5.4v5v5
		c-3.2-0.7-6.2-1.7-9-3.2c-1.7-0.9-3.3-1.9-4.9-3c-1.8-1.3-3.4-2.7-4.9-4.3c-1.9-2-3.5-4.2-4.9-6.5c-1.9-3.3-3.3-6.9-4.1-10.7
		c-0.3-1.6-0.6-3.2-0.7-4.9h-0.1H18c0.1,1.7,0.3,3.3,0.6,4.9c0.8,4.3,2.2,8.3,4.2,12c1.4,2.5,3,4.9,4.9,7.1c1.5,1.7,3.1,3.3,4.9,4.8
		c1.5,1.3,3.2,2.4,4.9,3.5c1.6,1,3.2,1.8,4.9,2.5c2.8,1.2,5.8,2.2,9,2.7c1.6,0.3,3.2,0.5,4.9,0.6V91v-4.9v-4.9v-5.1v-4.8
		c-1.7-0.3-3.4-0.8-4.9-1.5c-3.1-1.6-5.7-4.1-7.2-7.2c-0.8-1.5-1.3-3.1-1.5-4.9H42.3z"
        />
    </svg>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({ name: 'HaloLogoWhite' });
</script>
