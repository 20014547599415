<template>
    <svg
        viewBox="0 0 549 126.3"
        class="h-full"
        fill="#ffffff"
    >
        <path
            d="M158.2,103.8c-17.6,0.1-22.9-3.8-22.9-10c0-4.1,4-7,10.5-8.4v-0.5c-3.5-0.7-5.8-2.6-5.8-5.9
				c0-2.4,2-4.5,4.8-6l3.5-2.2c-6.7-2.3-10.3-7.4-10.3-14.3c0-10.6,7.5-16.3,20.3-16.3h17.6l-0.4,6.9c-2.5,0-5.5-0.2-9.7-0.6v0.9
				c6.4,1.3,11.7,4,11.7,10.8c0,8.7-6.8,13.9-19.8,13.9c-3,0-5.6-0.3-7.9-1l-1.3,2.1c-0.8,1.3-1.4,2.2-1.4,3.2c0,2,1.7,2.8,4.9,2.8
				c5.2,0,10.8-0.1,15.9-0.1c9.5,0,14.4,4.1,14.4,11.2C182.3,99.5,176.4,103.9,158.2,103.8z M175.1,90.6c0-3-2.1-5.3-7.3-5.3h-16.8
				c-5.8,0-8.5,3.1-8.5,6c0,4.2,3.4,6,16.1,6C172,97.3,175.1,95,175.1,90.6z M146.1,56.4c0,5.8,3.2,9.3,11.7,9.3
				c8.4,0,12-3.4,12-9.3c0-5.8-4.1-9.4-12.2-9.4C149.5,47,146.1,50.8,146.1,56.4z"
        />
        <path d="M186.2,31v-7.9h10V31H186.2z M186.8,86.9V40h8.7v46.8H186.8z"/>
        <path
            d="M216.6,86.9L198,40h9.5l6.3,17.9c2.5,7.2,5.2,14.4,7.3,21.4h2c2.1-6.8,4.8-14.2,7.3-21.4l6.2-17.9h9.3
				l-18.3,46.8H216.6z"
        />
        <path d="M247.8,31v-7.9h10V31H247.8z M248.4,86.9V40h8.7v46.8H248.4z"/>
        <path
            d="M274.8,86.9h-8.7V40h8.5v12.1h0.9c2.6-8.3,8.8-13.3,16.9-13.3c10.4,0,15.1,6.8,15.1,17v31h-8.7V58
				c0-6.4-2.6-11.2-10.1-11.2c-8.3,0-13.9,5.3-13.9,13.3V86.9z"
        />
        <path
            d="M333.2,103.8c-17.6,0.1-22.9-3.8-22.9-10c0-4.1,4-7,10.5-8.4v-0.5c-3.5-0.7-5.8-2.6-5.8-5.9
				c0-2.4,2-4.5,4.8-6l3.5-2.2c-6.7-2.3-10.3-7.4-10.3-14.3c0-10.6,7.5-16.3,20.3-16.3h17.4l-0.3,6.9c-2.5,0-5.5-0.2-9.7-0.6v0.9
				c6.4,1.3,11.7,4,11.7,10.8c0,8.7-6.8,13.9-19.8,13.9c-3,0-5.6-0.3-7.9-1l-1.3,2.1c-0.8,1.3-1.4,2.2-1.4,3.2c0,2,1.7,2.8,4.9,2.8
				c5.2,0,10.8-0.1,15.9-0.1c9.5,0,14.4,4.1,14.4,11.2C357.2,99.5,351.3,103.9,333.2,103.8z M350,90.6c0-3-2.1-5.3-7.3-5.3h-16.8
				c-5.8,0-8.5,3.1-8.5,6c0,4.2,3.4,6,16.1,6C346.9,97.3,350,95,350,90.6z M321,56.4c0,5.8,3.2,9.3,11.7,9.3c8.4,0,12-3.4,12-9.3
				c0-5.8-4.1-9.4-12.2-9.4C324.4,47,321,50.8,321,56.4z"
        />
        <path
            d="M384.7,86.9H376V22.5h8.7v29.7h0.7c2.7-8.2,8.6-13.3,17.1-13.3c10.2,0,14.9,6.8,14.9,17v31h-8.7V58
				c0-6.4-2.8-11.2-10-11.2c-8.5,0-14,5.3-14,13.3V86.9z"
        />
        <path
            d="M454.3,75.7h-0.9c-2.3,7.7-8.3,12.1-16.6,12.1c-8.8,0-14.5-4-14.5-13c0-8.1,5.2-14,16.6-14h15V57
				c0-7-2.7-10.8-10.3-10.8c-6.3,0-9.8,3.1-11.3,9.3l-8.2-2c1.9-9,8.6-14.5,19.8-14.5c13,0,18.7,6.3,18.7,17.9v30h-8.3V75.7z
				 M453.9,69.4v-2.6h-13.7c-6.1,0-9.6,2.4-9.6,7c0,3.7,2.3,7,9.2,7C448.3,80.8,453.9,76,453.9,69.4z"
        />
        <path d="M479.3,86.9h-8.7V22.5h8.7V86.9z"/>
        <path
            d="M484.8,63.3c0-15.3,9.2-24.5,23.5-24.5c14.1,0,23.5,9.1,23.5,24.5c0,15.2-8.6,24.8-23.5,24.8
				C493.5,88.1,484.8,78.5,484.8,63.3z M493.6,63.2c0,10.5,4.7,17.2,14.6,17.2c9.9,0,14.8-6.7,14.8-17.2s-5.1-16.8-14.7-16.8
				S493.6,52.7,493.6,63.2z"
        />
        <path d="M90.2,34.2v46.2H75.8V55.3L21.4,28.8h63.4C87.8,28.8,90.2,31.2,90.2,34.2z"/>
        <polygon points="70.7,58.5 70.7,104.4 21.4,80.5 18,78.8 18,32.9"/>
    </svg>
</template>

<script lang="ts">
import { defineComponent } from 'vue';

export default defineComponent({ name: 'GivingLogoWhite' });
</script>
